@import './variables.scss';
@import './_mixins.scss';

#primary {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #000000b3;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  height: 60vh;
  color: white;
  .main-btn {
    margin: auto;
    width: 300px;
    font-size: 18px;
  }
}

#secondary {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 11vw;
  background-color: #d9b382;
  padding-left: 5%;
  color: white;
}

#accent {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 11vw;
  background-color: green;
  padding-left: 5%;
  color: white;
}
.header-heading {
  font-size: 16px;
  margin-left: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @include max-mobile{
    display: none;
  }
}
.header-heading:hover {
  cursor: pointer !important;
  text-decoration: underline;
}
.logo2 {
  max-width: 50px;
  max-height: 50px;
  padding-left: 2vw;
}

.text-col {
  width: 100%;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.flex-cont2 {
  display: flex;
  width: 100%;
  min-height: 20px;
  align-items: center;
  border-bottom: 1px solid silver;
  margin-top: 2vw;
  padding-bottom: 1vw;
}
.flex-cont4 {
  display: flex;
  width: 100%;
  min-height: 20px;
  margin-bottom: 2vw;
  justify-content: space-between;
  margin-left: 3vw;
  width: 91%;
  border-bottom: 1px solid silver;
}
.acc-head {
  font-size: 1.3vw;
  width: 87%;
}
.bi-bt {
  width: 2.5vw;
  height: 2.5vw;
  cursor: pointer;
}
.message {
  padding: 0px 3.5vw;
  width: 92%;
  font-size: 1vw;
}
.freq {
  font-size: 2vw;
}
.flex-cont3 {
  display: flex;
  width: 100%;
  min-height: 20px;
  justify-content: center;
  padding-left: 2vw;
  margin-top: 20px;
  width: 97%;
}
.header-cont {
  background-color: $primary-color;
  color: white;
  display: flex;
  text-align: center;
  flex-direction: column;
  font-size: 24px;
}
.header-cont-2 {
  background-color: $primary-color;
  color: white;
  display: flex;
  text-align: center;
  flex-direction: column;
  font-size: 18px;
  a{
    color: white;
  }
}
.circle {
  width: 12px;
  height: 12px;
  background-color: orange;
  border-radius: 12px;
  margin-right: 20px;
}
.faq-container {
  margin-top: 0%;
  margin-bottom: 10%;
  margin-left: 5%;
  margin-right: 5%;
}
.logos {
  width: 13vw;
  height: 13vw;
}
.para {
  font-size: 3vw;
}
.one {
  width: 7vw;
  height: 4vw;
}
.ir-head {
  font-size: 24px;
}
.ir-para {
  font-size: 22px;
  text-align: center;
}
.sc {
  width: 100%;
  height: 100%;
}
.para2 {
  font-size: 2vw;
  color: #696666;
}
.inside {
  display: flex;
  justify-content: left;
  margin-top: -1.5vw;

  width: 100%;
  min-height: 20px;
}
.headers {
  padding-top: 5px;
  padding-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
  min-height: 20px;
  justify-content: center;
  text-align: center;
  background-color: white;
  h1 {
    color: $primary-color;
    font-size: 28px;
    b {
      font-weight: 500;
    }
  }
  .welcome-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.foot {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 20px;
  justify-content: space-between;
}
.flex-cont {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 20px;
  overflow-x: hidden;
  overflow-y: hidden;
}
.header {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 20px;
}
.header-text {
  font-size: 3.5vw;
  margin-right: 1vw;
}
.header-text2 {
  font-size: 3.5vw;
  margin-right: 1vw;
  font-size: 1.5vw;
}
.image-col {
  width: 20%;
}
.left {
  padding: 0 0%;
  display: flex;
  flex-direction: row;
  .ir-para {
    padding: 0 10%;
  }
}
.inner-left {
  width: 20%;
  align-items: center;
  display: flex;
}
.inner-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.btn {
  height: 65px;
  font-size: 16px;
  color: #000000;
  margin-top: 1.5vw;
  border-radius: 40px;
  width: 250px;
  outline: none;
  background-color: white;
  border: 1px solid silver;
}
.btn:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}
.profile-wrapper {
  .header-heading {
    color: gray;
  }
}

.proceed {
  width: 85%;
  height: 2.5vw;
  font-size: 1vw;
  margin-top: 1vw;
  background-color: steelblue;
  color: white;
  border: none;
  outline: none;
}
.btn3 {
  max-width: 300px;
  max-height: 50px;
  font-size: 16px;
  background-color: #98032e;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  height: 5vw;
}
.btn3:hover {
  cursor: pointer !important;
  background-color: black;
}

.btn2 {
  color: white;
  background-color: steelblue;
  width: 30%;
  height: 5vw;
  border: none;
  outline: none;
  border-radius: 1vw;
}
.right {
  width: 57%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  border: 2px solid gray;
}
.right-header {
  background-color: #000000;
  height: 4vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left-cont {
  width: 45%;
  display: flex;
  height: 18vw;
  align-items: center;
  justify-content: center;
}
.right-cont {
  width: 55%;
  display: flex;
  max-height: 23vw;
  flex-direction: column;
  overflow-y: scroll;
}
.footer-logos {
  width: 4vw;
  height: 4vw;
  margin-top: 6.8vw;
  margin-left: 3vw;
}
.foot-texts {
  font-size: 1.5vw;
  margin-bottom: -0.5vw;
  color: white;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.inputs {
  width: 85%;
  margin-top: 0.4vw;
  height: 2vw;
  font-size: 1vw;
  border: 1px solid silver;
}
.bottom-texts {
  font-size: 1.5vw;
  color: white;
}
.logo-texts {
  font-size: 20px;
  color: white;
  margin-left: -42vw;
  margin-top: 7.3vw;
}
.banner {
  width: 100%;
  text-align: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
  .btn {
    width: 300px;
  }
}
.banner-paras {
  font-size: 42px;
}
#margin {
  margin-top: 4vw;
}

.footer-logo-cont-small {
  display: none;
}

.footers {
  width: 100%;
  border: 1px solid red;
}

.footer {
  background: black;
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
}
.badge-cont {
  display: flex;
  align-items: center;
  height: 150px;
}
.footer-badge {
  margin-right: 10px;
  height: 80px;
}
.links-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.copyright-cont {
  display: flex;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}
.logo-wrapper {
  display: flex;
}
.whole-footer {
  background: black;
}
@media only screen and (max-width: 600px) {
  .logo2 {
    width: 60px;
    height: 60px;
    padding: 0;
  }
  #secondary {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text-col {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .headers {
    justify-content: center;
    align-items: center;
  }
  .header {
    height: 140px;
  }
  .header-heading {
    font-size: 16px;
  }
  .inner-left {
    margin-top: 20px;
  }
  .flex-cont2 {
    flex-direction: column;
    margin-top: 5vw;
  }
  .left {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  .header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .image-col {
    display: none;
  }
  .header-text {
    font-size: 48px;
    align-items: center;
  }
  .header-text2 {
    margin-top: -5vw;

    font-size: 30px;
    font-weight: lighter;
    align-items: center;
  }
  .banner-paras {
    font-size: 22px;
    align-items: center;
  }
  .inside {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .para {
    font-size: 36px;
  }
  .para2 {
    font-size: 24px;
    margin-top: 3vw;
    z-index: 10000;
  }
  .one {
    width: 90px;
    height: 55px;
  }
  .ir-head {
    font-size: 24px;
  }
  .ir-para {
    font-size: 24px;
    margin-top: 7vw;
    text-align: center;
  }
  .btn {
    width: 257px;
    height: 55px;
    font-size: 14px;
    font-weight: lighter;
    border-radius: 5px;
    margin-bottom: 3vw;
  }

  .right {
    width: 90%;
    padding: 0;
    margin-left: 0px;
  }
  .sc {
    margin: 0;
  }
  .btn3 {
    height: 54px;
    margin-bottom: 1vw;
    :hover {
      cursor: pointer !important;
    }
  }
  .acc-head {
    font-size: 18px;
  }
  .bi-bt {
    width: 20px;
    height: 20px;
    margin-top: 4.5vw;
  }
  .message {
    font-size: 12px;
  }
  .freq {
    font-size: 36px;
  }
  .flex-cont3 {
    text-align: center;
  }
  .foot {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
  .logo-texts {
    margin-left: 0;
    margin-top: 15vw;
    font-size: 15px;
  }
  .foot-texts {
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
  .footer-logos {
    display: none;
    margin-top: -18vw;
    margin-left: -5vw;
  }
  .footer-logo-cont-small {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: center;
    padding: 0px;
    margin: 0px;
  }
  .footer-logo {
    width: 60px;
    height: 60px;
  }
  .footer-col {
    width: 100%;
  }
  .bottom-texts {
    font-size: 4vw;
    margin-top: 40px;
  }
  .footer {
    background: black;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 0px 0px;
  }
  .badge-cont {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
  }
  .footer-badge {
    margin-right: 0px;
    height: 30px;
  }
  .links-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
