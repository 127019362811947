.header-nav{
    display: grid;
    grid-template-columns: 25% 50% 25%;
    align-items: center !important;
    justify-content: space-between;
    .logo-wrapper {
        align-items: center;
      }
      h1 {
        font-size: 18px;
      }
      .navigation-links {
        text-align: center;
     
        span {
          display: inline-flex;
          align-items: center;
          color: gray;
          font-size: 0.9rem;
          margin-right: 1rem;
          &:hover {
            text-decoration: underline !important;
            cursor: pointer;
          }
        }
      }
}