@import "./css/_mixins";

.main-card{
    width: 95vh;
    margin: auto;
    margin-top: 5%; 
    margin-bottom: 10%; 
    padding-bottom: 5%; 
    .logo-wrapper{
        text-align: center;
        padding-top: 20px; 
        justify-content: center;
    }
    p{
        overflow-wrap: break-word;
    }
    @include max-mobile{
        width: 95vw;
    }
}
.body-text{
    text-align: left;
    padding: 0 5%; 
    h3{
        font-size: 1.7rem;
    }
    p{
        font-size: 1.5rem; 
    }
}