@import './variables.scss';
@import './_mixins.scss';

.primary .banner {
  background-color: $team-color;
}
.primary .right-btn {
  background-color: $team-color;
}
.secondary .banner {
  background-color: $team-secondary-color;
}
.secondary .right-btn {
  background-color: $team-secondary-color;
}
.accent .banner {
  background-color: green;
}
.accent .right-btn {
  background-color: green;
}
.logo {
  width: 6vw;
  height: 6vw;
  padding-left: 2vw;
}
.header-heading {
}
.banner-heading {
  font-size: 3rem;
}
.btn-main {
  margin: auto !important;
  padding: 10px 10px !important;
  height: initial;
}
.initials {
  display: flex;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: black;
  color: white;
  flex-direction: inherit;
  align-items: center;
  justify-content: center;
}
.banner-para {
  font-size: 1.3rem;
  margin-top: -1vw;
  width: 60% !important;
}
.no-seats {
  text-align: center;
  font-weight: 500;
}
.underline {
  text-decoration: underline;
}
.main-cont {
  padding: 0 10px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  min-height: 3px;
  .team-scroll-container {
    h2 {
      text-align: center;
    }
    h4 {
      text-align: center;
      margin-top: 5px;
      font-weight: 400;
    }
    h3 {
      text-align: center;
      font-weight: 500;
      margin-top: 10px;
      color: #98032e;
    }
    h3:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .selection-menu {
    padding-bottom: 2%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }

  .seat-count-select {
    justify-content: center;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.61);
    -webkit-border-radius: 100px;
  }
}
.creditAvailable {
  font-family: sans-serif !important;
}
.seat-count-item {
  width: 50px;
  height: 50px;
  background-color: white;
  border: 1px solid black !important;
  color: black !important;
  border-radius: 50%;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  span {
    margin: auto;
    color: black;
    font-weight: bold;
  }
}
.event-row:hover {
  background: gray;
}
.location-container {
  margin: auto;
  width: 70%;
  margin-bottom: 10%;
  .event-row {
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
  }
  .event-row:hover {
    border-radius: 10px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(36, 63, 97, 0.11) 0px 2px 12px,
      rgba(36, 63, 97, 0.12) 0px 0.5px 2px;
  }
}

.avail {
  font-size: 1.5vw;
  padding-left: 1vw;
}
.big-para {
  font-size: 1.5vw;
  padding-left: 1vw;
}
.big-para2 {
  font-size: 1.44vw;
  color: #7c7979;
  padding-right: 1vw;
  margin-right: 10px;
  text-align: right;
}
.big-logo {
  height: 70px;
  margin: auto;
}
.col-head {
  font-size: 1.6vw;
}
.event-row {
  display: grid;
  grid-template-columns: 25% 25% 25% !important;
  @include max-mobile {
    grid-template-columns: 35% 25% 25% !important;
  }
  padding-top: 10px;
  padding-bottom: 10px;
  .col-head {
    margin-bottom: 0;
  }
  .col-para {
    margin-top: 0;
  }
}
.section {
  font-weight: 700 !important;
  margin-bottom: 0;
}
.seatDetails {
  margin-top: 0;
  margin-bottom: 0;
  font-family: sans-serif;
  font-weight: 300 !important;
}
.col-para p {
  margin-top: 0;
  margin-bottom: 0;
}
.price {
  font-size: 1.2rem;
  @include max-mobile {
    font-size: 1.2rem !important;
  }
  span {
    // font-size: 14px;
  }
}
.small-head {
  font-size: 5vw;
}
.small-para {
  font-size: 2.5vw;
  margin-top: -1.5vw;
}
.link {
  font-size: 2vw;
  margin-top: 2vw;
}
.footer-logo {
  width: 4vw;
  height: 4vw;
  margin-top: 6.8vw;
  margin-left: 3vw;
}
.logo-text {
  font-size: 2vw;
  color: white;
  margin-left: -51vw;
  margin-top: 7.3vw;
}
.bottom-text {
  font-size: 1.5vw;
  color: white;
}
.flex-cont {
  background: none;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  flex-direction: row;
  min-height: 3px;
}
.transX {
  grid-template-columns: auto auto auto auto !important;
  .sold {
    background: rgb(0, 194, 253);
    padding: 5px 15px;
    font-size: 1rem;
    color: white;
    border-radius: 10px;
  }
  .pending {
    background: rgb(194, 90, 5);
    padding: 5px 15px;
    font-size: 1rem;
    color: white;
    border-radius: 10px;
  }
}

body {
  background-color: #e5e5e5;
}
.banner {
  display: flex;
  align-items: left;
  justify-content: center;
  color: white;
  flex-direction: column;
  height: 20vh;
}
.big-card {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: white;
  border-radius: 1vw;
  min-height: 10vw;
  margin: auto;
  margin-bottom: 3%;
}
.headers {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: space-between;
  .logo-wrapper {
    align-items: center;
  }
  h1 {
    font-size: 18px;
  }
  .navigation-links {
    a {
      color: gray;
      font-size: 0.9rem;
      margin-right: 1rem;
      &:hover {
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }
}
.whole-footer {
  display: none;
}
.small-card {
  background-color: $team-secondary-color;
  display: flex;
  // min-height: 10vw;
  max-height: 100px;
  height: fit-content;
  flex-direction: row;
  justify-content: space-around;
  // align-items: center;
  width: 35%;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: black;
  // text-align: center;
  .submitted {
    color: green;
  }
  h1 {
    font-size: 36px;
  }
  span {
    font-size: 22px;
    font-weight: 500;
  }
  .btn-wrapper {
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin-right: 20px;
      font-size: 16px;
      color: #282c2f;
      text-decoration: underline;
    }
    span:hover {
      cursor: pointer;
    }
    button {
      font-weight: bold;
    }
  }
}
.logo-wrapper {
  display: flex;
  flex-direction: row;
}
.profile-wrapper {
  .header-heading {
    margin-right: 10px;
  }
  .initials {
    margin-right: 10px;
  }
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.col-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.check-box {
  width: 2.3vw;
  height: 2.3vw;
  border: 3px solid black;
  margin: auto;
  :hover {
    cursor: pointer;
  }
}
.card2 {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  background-color: white;
  min-height: 19vw;
  border: 1px solid black;
  align-items: center;
  border-radius: 1vw;
}
.right-btn {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
}
.footers {
  background-color: black;
  display: flex;
  min-height: 15vw;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  bottom: 0;
  padding-bottom: 80px;
  clear: both;
}
.summary-footer {
  background-color: black;
  width: 100%;

  position: fixed;
  height: 100px;
}
.footer-col {
  display: flex;
  flex-direction: column;
  margin-right: 7vw;
  text-align: right;
  margin-top: 3vw;
}
.foot-text {
  font-size: 1.5vw;
  margin-bottom: -0.5vw;
  color: white;
}

.space-bottom {
  margin-bottom: 90px;
}
.price-container {
  height: 100%;
  display: flex;
  font-family: sans-serif;
  font-weight: 300;
  .price {
    margin: auto;
  }
}
.checkbox-container {
  width: 100px;
  height: 100%;
  text-align: center;
  display: flex;
  margin: auto;
  input {
    margin: auto;
  }
}

// menu items
.menu-item {
  padding: 10px 20px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  border: 1px blue solid;
}
.menu-item.active {
  background: #dadada;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid black;
}
.menu-item.active.seat-count-item {
  background: black;
  span {
    color: white !important;
  }
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}
.team-menu-item {
  display: flex;
  flex-direction: row;
  .event {
    flex-direction: column;
    display: flex;
    margin: auto;
    width: 200px;
  }
  .team-logo {
    margin: 0;
    margin-right: 20px;
    padding: 0;
    padding-left: 0 !important;
    width: 4vw;
    height: 4vw;
  }
}

@media only screen and (max-width: 600px) {
  .primary {
  }
  .event-row {
    padding-top: 10px;
    padding-bottom: 10px;
    .col-head {
      margin-bottom: 0;
    }
    .col-para {
      margin-top: 0;
    }
  }
  .logo {
    width: 9vw;
    height: 9vw;
    padding-left: 2vw;
  }
  .team-menu-item .team-logo {
    margin: auto;
    width: 15vw;
    height: 15vw;
    margin-right: 20px;
  }
  .header-heading {
    margin-left: 1vw;
  }
  .banner-heading {
    width: 90%;
    font-size: 36px;
  }
  .banner-para {
    font-size: 22px;
    margin-top: -1vw;
    width: 60% !important;
  }
  .flex-cont {
    justify-content: space-evenly;
    align-items: center;
  }
  .price-container {
    margin: 5px;
  }
  .location-container {
    width: 100%;
    .price {
      // width: 60px;
      // font-size: 14px;
    }
    .col-para {
      font-size: 18px;
    }
  }
  .seat-count-item {
    height: 35px;
    width: 35px;
  }

  .menu-item {
    margin: 5px;
  }

  .big-card {
    width: 90%;
  }
  .small-card a {
    margin: 10px 0px;
  }
  .right-btn {
    width: 100%;
  }

  .avail {
    font-size: 2.5vw;
    padding-left: 1vw;
  }
  .big-para {
    font-size: 2.5vw;
    padding-left: 1vw;
  }
  .big-para2 {
    font-size: 3vw;
    color: #7c7979;
    margin: 0px 20px;
    text-align: right;
  }
  .big-logo {
    height: 80px;
  }
  .col-head {
    font-size: 4vw;
  }
  .col-para {
    font-size: 2vw;
    color: black;
  }
  .small-head {
    font-size: 7vw;
  }
  .small-para {
    font-size: 3.5vw;
    margin-top: -1.5vw;
  }
  .link {
    font-size: 3vw;
    margin-top: 2vw;
  }
  .footer-logo {
    width: 5vw;
    height: 5vw;
    margin-top: 6.8vw;
    margin-left: 3vw;
  }
  .logo-text {
    font-size: 3vw;
    color: white;
    margin-left: -42vw;
    margin-top: 7.3vw;
  }
  .bottom-text {
    font-size: 3vw;
    color: white;
  }
  .check-box {
    width: 4vw;
    height: 4vw;
    border: 3px solid black;
    :hover {
      cursor: pointer;
    }
  }

  .space {
    margin: 10px 0px;
  }
  .seat-count-item span {
    margin: 5px auto;
    color: black;
    font-weight: bold;
  }
  .initials {
    display: none;
  }
  .headers {
    text-align: left;
  }

  .col-cont {
    // flex-direction: column-reverse;
    // align-items: center;
  }
  .col-para {
    margin: 5px 0;
  }
  .col-head {
    margin: 0 0 10px 0;
  }
}

@media only screen and (max-width: 467px) {
  .team-menu-item .team-logo {
    margin: auto;
    width: 15vw;
    height: 15vw;
    margin-right: 20px;
  }
  .headers {
    flex-direction: column;
  }
  .banner {
    height: 25vh;
  }
  .seat-count-item {
    height: 35px;
    width: 35px;
  }
  .menu-item {
    margin: 5px;
  }
  .seat-count-item span {
    margin: 7px auto;
    color: black;
    font-weight: bold;
  }
  .initials {
    display: none;
  }
  .display-column {
    display: flex;
    flex-direction: column;
  }
}

.subs-btn-wrap {
  text-align: center;
  display: flex;
  justify-content: center;
}

.btn-subscribe,
.subscribe-confirm-btn {
  background-color: $team-color;
  cursor: pointer;
  color: #ffffff;
  border: unset;
  padding: 15px 25px;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  border-radius: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  span {
    margin-left: 5px;
  }

  &:hover {
    background-color: lighten($team-color, 5%);
  }
}

.subscribe-confirm-btn {
  background-color: $team-color !important;
  transition: all 0.3s ease !important;
}

.subs-phone-input[type='number'] {
  max-width: unset;
  min-width: 200px !important;
}
