.App {
    text-align: center;
    background: green
  }
  body{
    font-family: 'Raleway';
    margin: 0;
  }
  .center{
    justify-content: center;
    text-align: center;
  }
  .badge{
    color: black;
    width: fit-content;
    padding: 5px; 
    border-radius: 5px;
    font-weight: bold;
    background-image: linear-gradient(to right, #c6c5c5 0%, #808285 100%);
  }
  .btn-main{
    background-color: #B17D58 !important;
    color: white !important;
    padding: 0 10px; 
  }